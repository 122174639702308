import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Building, Pencil, X, ArrowLeft, 
  MapPin, User, Camera, Tag, Home,
  DollarSign, Users
} from 'lucide-react';
import api from '../apiConfig/api';

const ImageThumbnail = React.memo(({ image, index, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const imageUrl = useMemo(() => {
    if (!image?.path) return null;
    // Use the direct URL instead of constructing with auth token
    return `https://ras-tndma.in/uploads/survey/${image.path}`;
  }, [image]);

  if (!imageUrl) return null;

  return (
    <div 
      className="aspect-square rounded-lg overflow-hidden border border-gray-200 cursor-pointer relative bg-gray-50"
      onClick={onClick}
    >
      {isLoading && !hasError && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      )}
      <img
        src={imageUrl}
        alt={`Property ${index + 1}`}
        className={`w-full h-full object-cover transition-all duration-300 ${
          isLoading || hasError ? 'opacity-0' : 'opacity-100 hover:scale-105'
        }`}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setHasError(true);
        }}
      />
      {hasError && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <Camera className="h-8 w-8 text-gray-400" />
        </div>
      )}
    </div>
  );
});

const ImageGallery = React.memo(({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  
  const imageArray = useMemo(() => {
    try {
      // Handle string input
      const parsed = typeof images === 'string' ? JSON.parse(images) : images;
      
      // Handle array of objects with path property
      if (Array.isArray(parsed)) {
        return parsed.map(item => {
          if (typeof item === 'string') {
            return { path: item };
          }
          return item;
        });
      }
      
      // Handle array-like object
      if (parsed && typeof parsed === 'object') {
        return Object.values(parsed).map(item => {
          if (typeof item === 'string') {
            return { path: item };
          }
          return item;
        });
      }
      
      return [];
    } catch (error) {
      console.error('Failed to parse images:', error);
      return [];
    }
  }, [images]);

  if (!imageArray?.length) return null;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {imageArray.map((image, index) => (
        <ImageThumbnail
          key={`${image.path}-${index}`}
          image={image}
          index={index}
          onClick={() => setSelectedImage(image)}
        />
      ))}

      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative max-w-4xl w-full">
            <ImageThumbnail
              image={selectedImage}
              index={imageArray.indexOf(selectedImage)}
              onClick={(e) => e.stopPropagation()}
            />
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setSelectedImage(null);
              }}
              className="absolute top-4 right-4 p-2 bg-white rounded-full hover:bg-gray-100"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

const InfoCard = ({ icon: Icon, title, children }) => (
  <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4">
    <div className="flex items-center gap-3 mb-3">
      <div className="bg-blue-50 p-2 rounded-lg">
        <Icon className="h-5 w-5 text-blue-600" />
      </div>
      <h3 className="font-semibold text-gray-900">{title}</h3>
    </div>
    {children}
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className="py-2">
    <div className="text-sm text-gray-500">{label}</div>
    <div className="font-medium text-gray-900">{value || 'N/A'}</div>
  </div>
);

const formatCurrency = (amount) => {
  if (!amount && amount !== 0) return 'N/A';
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  }).format(amount);
};

const AssetReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [assetData, setAssetData] = useState(null);

  useEffect(() => {
    const fetchAssetDetails = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`/asset/report/${id}`);
        setAssetData(response.data.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssetDetails();
  }, [id]);

  const ownerDetails = useMemo(() => {
    try {
      return assetData?.owner_det ? JSON.parse(assetData.owner_det) : null;
    } catch (error) {
      console.error('Error parsing owner details:', error);
      return null;
    }
  }, [assetData?.owner_det]);

  const profTaxDetails = useMemo(() => {
    // Check if prof_tax is already an object or a string that needs parsing
    if (assetData?.prof_tax) {
      if (typeof assetData.prof_tax === 'object') {
        return assetData.prof_tax;
      } else {
        try {
          return JSON.parse(assetData.prof_tax);
        } catch (error) {
          console.error('Error parsing prof tax details:', error);
          return null;
        }
      }
    }
    return null;
  }, [assetData?.prof_tax]);

  const handleRequestEdit = () => {
    navigate(`/verify/${id}`, { state: assetData });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 mt-16">
      <div className="max-w-7xl mx-auto px-4">
        <header className="sticky top-0 z-50 backdrop-blur-md bg-white/90 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate(-1)}
                className="p-2 text-gray-600 hover:text-gray-900 transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
              <div className="flex flex-col">
                <div className="flex items-center gap-3">
                  <Tag className="h-5 w-5 text-blue-600" />
                  <span className="text-sm font-medium text-gray-600">#{assetData?.asst_ref}</span>
                </div>
                {assetData?.created_by && (
                  <div className="text-xs text-gray-500 mt-1">
                    {(() => {
                      try {
                        const createdBy = typeof assetData.created_by === 'string' 
                          ? JSON.parse(assetData.created_by) 
                          : assetData.created_by;
                        return `Created by: ${createdBy.name}, ${createdBy.mobile}, ${createdBy.role}`;
                      } catch (error) {
                        console.error('Error parsing created_by:', error);
                        return 'Created by: Unknown';
                      }
                    })()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <main className="py-4 grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <InfoCard icon={Building} title="Organization Details">
            <DetailRow label="Organization Name" value={assetData?.org_name} />
            <DetailRow label="Zone" value={assetData?.zone_name} />
            <DetailRow label="Ward" value={assetData?.ward_name} />
          </InfoCard>

          <InfoCard icon={User} title="Owner Details">
            <DetailRow label="Owner Name" value={assetData?.owner} />
            <DetailRow label="Mobile Number" value={ownerDetails?.mobile} />
          </InfoCard>

          <InfoCard icon={Home} title="Property Details">
            <DetailRow label="Usage Type" value={assetData?.usage} />
            <DetailRow label="Build Area (as per Record)" value={`${assetData?.buildarea || 'N/A'} sq.ft`} />
            <DetailRow label="Build Area (as Observed)" value={`${assetData?.area || 'N/A'} sq.ft`} />
            {profTaxDetails?.commercial_area && (
              <DetailRow 
                label="Commercial Area" 
                value={`${profTaxDetails.commercial_area} sq.ft`} 
              />
            )}
          </InfoCard>

          <InfoCard icon={MapPin} title="Professional Tax Details">
            {profTaxDetails?.entities && profTaxDetails.entities.length > 0 ? (
              <>
                <DetailRow 
                  label="Total Tax Amount" 
                  value={formatCurrency(profTaxDetails.total_tax)} 
                />
                {profTaxDetails.entities.map((entity, index) => (
                  <div key={entity.prof_tax_no || index} className={index > 0 ? "mt-4 pt-4 border-t border-gray-200" : ""}>
                    <DetailRow 
                      label={`Professional Tax ID ${profTaxDetails.entities.length > 1 ? (index + 1) : ''}`}
                      value={entity.prof_tax_no} 
                    />
                    {entity.firm_name && <DetailRow label="Firm Name" value={entity.firm_name} />}
                  </div>
                ))}
              </>
            ) : (
              <DetailRow label="Professional Tax ID" value="N/A" />
            )}
          </InfoCard>
          
          {profTaxDetails?.entities && profTaxDetails.entities.length > 0 && (
            <div className={profTaxDetails.entities.length > 1 ? "md:col-span-2 xl:col-span-4" : "md:col-span-2"}>
              <InfoCard icon={Users} title="Employee Details">
                {profTaxDetails.entities.map((entity, entityIndex) => (
                  <div key={entity.prof_tax_no || entityIndex} className={entityIndex > 0 ? "mt-6 pt-4 border-t border-gray-200" : ""}>
                    {profTaxDetails.entities.length > 1 && (
                      <div className="mb-3 font-medium text-gray-700">
                        {entity.firm_name || `Entity ${entityIndex + 1}`} 
                        {entity.prof_tax_no ? ` (${entity.prof_tax_no})` : ''}
                      </div>
                    )}
                    
                    <DetailRow 
                      label="Total Employees" 
                      value={entity.total_employees} 
                    />
                    <DetailRow 
                      label="Entity Tax Amount" 
                      value={formatCurrency(entity.total_tax)} 
                    />
                    
                    {entity.emp_details && (
                      <div className="mt-3">
                        <div className="text-sm font-medium text-gray-700 mb-2">Employee Salary Distribution</div>
                        <div className="overflow-x-auto">
                          <table className="w-full border-collapse">
                            <thead>
                              <tr className="bg-gray-50">
                                <th className="text-left p-2 text-xs font-medium text-gray-500">Salary Range</th>
                                <th className="text-left p-2 text-xs font-medium text-gray-500">Count</th>
                                <th className="text-right p-2 text-xs font-medium text-gray-500">Tax Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(entity.emp_details).map((key) => {
                                const range = entity.emp_details[key];
                                return (
                                  <tr key={key} className="border-t border-gray-200">
                                    <td className="p-2 text-sm text-gray-600">
                                      {range.range || 'Base salary'}
                                    </td>
                                    <td className="p-2 text-sm text-gray-600">
                                      {range.count}
                                    </td>
                                    <td className="p-2 text-sm text-gray-600 text-right">
                                      {formatCurrency(range.tax)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </InfoCard>
            </div>
          )}

          {assetData?.images && (
            <div className="md:col-span-2 xl:col-span-4">
              <InfoCard icon={Camera} title="Property Images">
                <div className="mt-3">
                  <ImageGallery images={assetData.images} />
                </div>
              </InfoCard>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default AssetReport;