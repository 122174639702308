import React, { useState } from 'react';
import { ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';

// Sub-components for the asset details
const SortButton = ({ label, field, sortConfig, onSort }) => (
  <button
    onClick={() => onSort(field)}
    className="flex items-center gap-1 px-3 py-1.5 text-sm border rounded-lg hover:bg-gray-50"
  >
    {label}
    {sortConfig.key !== field ? (
      <ArrowUpDown className="h-4 w-4" />
    ) : sortConfig.direction === 'asc' ? (
      <ArrowUp className="h-4 w-4" />
    ) : (
      <ArrowDown className="h-4 w-4" />
    )}
  </button>
);

const CompletedSurveyHeader = ({ sortConfig, onSort }) => (
  <div className="hidden lg:grid grid-cols-2 gap-4 py-3 border-b border-gray-200 bg-gray-50 rounded-t-lg px-4">
    <div className="col-span-1">
      <h4 className="text-sm font-medium text-gray-600">Ward Details</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Completed</h4>
    </div>
  </div>
);

const CompletedSurveyItem = ({ report, onCompletedClick }) => (
  <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 py-3 border-b border-gray-100 last:border-0 px-4 hover:bg-gray-50">
    <div className="col-span-1">
      <h3 className="font-medium text-gray-800">{report.ward_name}</h3>
      <div className="text-xs text-gray-500 mt-1">
        <p>{report.org_name}</p>
        <p>{report.zone_name}</p>
      </div>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Completed:</span>
      <button
        onClick={() => onCompletedClick(report)}
        className="font-medium text-green-600 hover:text-green-700 transition-colors hover:underline"
      >
        {report.completed || '0'}
      </button>
    </div>
  </div>
);

const LoadingItem = () => (
  <div className="grid grid-cols-2 gap-4 py-3">
    <div className="col-span-1 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const AssetDetailsSection = ({ isLoading, assetReports, onCompletedClick }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return assetReports;

    return [...assetReports].sort((a, b) => {
      const aValue = parseInt(a[sortConfig.key]) || 0;
      const bValue = parseInt(b[sortConfig.key]) || 0;
      
      if (sortConfig.direction === 'asc') {
        return aValue - bValue;
      }
      return bValue - aValue;
    });
  }, [assetReports, sortConfig]);

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
          <h2 className="text-lg font-semibold text-gray-800">Asset Details</h2>
          <div className="flex gap-2">
            <SortButton 
              label="Completed"
              field="completed"
              sortConfig={sortConfig}
              onSort={handleSort}
            />
          </div>
        </div>
        <div className="rounded-lg border border-gray-200">
          <CompletedSurveyHeader sortConfig={sortConfig} onSort={handleSort} />
          <div className="divide-y divide-gray-200">
            {isLoading ? (
              <div className="px-4">
                {[1, 2, 3].map((i) => (
                  <LoadingItem key={i} />
                ))}
              </div>
            ) : sortedData.length > 0 ? (
              sortedData.map((report, index) => (
                <CompletedSurveyItem
                  key={index}
                  report={report}
                  onCompletedClick={onCompletedClick}
                />
              ))
            ) : (
              <p className="text-gray-500 text-center py-4">No completed surveys found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailsSection;