import React from 'react';
import { ClipboardCheck, Calendar } from 'lucide-react';

const LoadingValue = () => (
  <span className="animate-pulse">
    <span className="h-8 w-16 bg-gray-200 rounded inline-block"></span>
  </span>
);

const getColorClasses = (color) => ({
  blue: { bg: "bg-blue-600", light: "bg-blue-50", text: "text-blue-600" },
  purple: { bg: "bg-purple-600", light: "bg-purple-50", text: "text-purple-600" },
  green: { bg: "bg-green-600", light: "bg-green-50", text: "text-green-600" }
}[color]);

const StatsCards = ({ isLoading, surveyStats }) => {
  const statsData = [
    {
      title: "Completed Surveys",
      value: isLoading ? <LoadingValue /> : surveyStats.totalCompleted.toLocaleString(),
      icon: ClipboardCheck,
      color: "green"
    },
    {
      title: "Monthly Survey Count",
      value: isLoading ? <LoadingValue /> : surveyStats.monthlyCount.toLocaleString(),
      icon: Calendar,
      color: "blue"
    }
  ];
  
  return (
    <div className="w-full pt-10 pb-6">
      <div className="flex justify-between items-start px-4 mx-auto max-w-6xl">
        <div className="w-5/12">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className={`h-1 ${getColorClasses("green").bg}`} />
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div className={`p-3 rounded-lg ${getColorClasses("green").light}`}>
                  <ClipboardCheck className={`h-6 w-6 ${getColorClasses("green").text}`} />
                </div>
                <div className="text-2xl font-bold text-gray-800">
                  {isLoading ? <LoadingValue /> : surveyStats.totalCompleted.toLocaleString()}
                </div>
              </div>
              <div className="mt-4 text-sm font-medium text-gray-600">Completed Surveys</div>
            </div>
          </div>
        </div>
        
        <div className="w-5/12">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className={`h-1 ${getColorClasses("blue").bg}`} />
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div className={`p-3 rounded-lg ${getColorClasses("blue").light}`}>
                  <Calendar className={`h-6 w-6 ${getColorClasses("blue").text}`} />
                </div>
                <div className="text-2xl font-bold text-gray-800">
                  {isLoading ? <LoadingValue /> : surveyStats.monthlyCount.toLocaleString()}
                </div>
              </div>
              <div className="mt-4 text-sm font-medium text-gray-600">Monthly Survey Count</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCards;