import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, ChevronDown, UserCircle, LogOut, Home, ClipboardList, BarChart2, Phone } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSurvey } from '../context/surveycontext';

const Header = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSurveyEnabled, surveyParams } = useSurvey();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [headerInfo, setHeaderInfo] = useState(() => {
    try {
      const stored = localStorage.getItem('headerInfo');
      return stored ? JSON.parse(stored) : null;
    } catch {
      return null;
    }
  });

  // Get user data from localStorage
  const userData = (() => {
    try {
      const stored = localStorage.getItem('userData');
      return stored ? JSON.parse(stored) : null;
    } catch {
      return null;
    }
  })();

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const stored = localStorage.getItem('headerInfo');
        if (stored) setHeaderInfo(JSON.parse(stored));
      } catch (error) {
        console.error('Error parsing header info:', error);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    const interval = setInterval(handleStorageChange, 1000);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setUserDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [userDropdownOpen]);

  const isAuthorizedRole = role => ['Tax Collector', 'Revenue Assistant'].includes(role);
  const formatTime = (date) => date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });

  const handleNavigation = (path, params = null) => {
    if (path === '/filters' && !isSurveyEnabled) {
      console.log('Survey is not enabled. Please wait for data to load.');
      return;
    }
    navigate(path, params ? { state: params } : undefined);
    setIsMenuOpen(false);
    setUserDropdownOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setUserDropdownOpen(false);
    setIsMenuOpen(false);
    navigate('/login', { replace: true });
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const isActive = (path) => location.pathname === path;

  const NavButton = ({ icon: Icon, label, path, disabled, isMobile, onClick }) => (
    <button 
      onClick={onClick || (() => handleNavigation(path))}
      disabled={disabled}
      className={`
        flex items-center transition-colors
        ${isMobile ? 
          `w-full px-4 py-3 rounded-lg ${
            disabled ? 'opacity-50 cursor-not-allowed text-gray-500' :
            isActive(path) ? 'bg-[#75d1e3]/10 text-black font-semibold border-l-4 border-[#75d1e3]' :
            'text-black hover:bg-[#75d1e3]/10'
          }` :
          `space-x-2 ${
            disabled ? 'opacity-50 cursor-not-allowed text-gray-500' :
            isActive(path) ? 'border-b-2 border-black font-semibold text-black' :
            'text-black hover:text-black/80'
          }`
        }
      `}
    >
      <Icon className={isMobile ? 'h-5 w-5 mr-3' : 'h-5 w-5'} />
      <span className="font-medium">{label}</span>
    </button>
  );

  return (
    <header className="relative z-50">
      <div className="bg-gradient-to-r from-[#75d1e3] via-[#67c3d5] to-[#75d1e3] py-3 lg:py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            {/* Keep logo and title section unchanged */}
            <div className="flex items-center space-x-4 lg:space-x-6">
              <img 
                src="/images/tnlogo.png"
                alt="TN Government Logo" 
                className="h-10 w-10 lg:h-14 lg:w-14 object-contain cursor-pointer transition-transform hover:scale-105"
                onClick={() => handleNavigation('/home')}
              />
              <div className="text-black border-l-2 border-black/20 pl-4 lg:pl-6">
                <h1 className="text-[1rem] lg:text-xl font-bold tracking-wide">Government of Tamil Nadu</h1>
                <p className="text-xs lg:text-sm text-black/90 mt-0.5 lg:mt-1">e-Governance Portal</p>
              </div>
            </div>

            <div className="hidden md:flex items-center space-x-4 lg:space-x-8">
              <nav className="flex items-center space-x-4 lg:space-x-8">
                <NavButton icon={Home} label="Home" path="/home" />
                {headerInfo && <>
                  {isAuthorizedRole(headerInfo.role) && (
                    <NavButton 
                      icon={ClipboardList} 
                      label="Survey" 
                      path="/filters" 
                      disabled={!isSurveyEnabled}
                      onClick={() => handleNavigation('/filters', surveyParams)}
                    />
                  )}
                  <NavButton icon={BarChart2} label="Reports" path="/generatereport" />
                </>}
                
                {/* Updated user dropdown section */}
                <div className="relative user-dropdown" ref={dropdownRef}>
                  <button 
                    onClick={toggleUserDropdown}
                    className="flex items-center space-x-2 text-black font-medium p-2 rounded-lg hover:bg-[#67c3d5]/20 transition-colors"
                  >
                    <UserCircle className="h-5 w-5" />
                    <span className="max-w-[120px] truncate">{headerInfo?.name || 'User'}</span>
                    <ChevronDown className={`h-4 w-4 transition-transform ${userDropdownOpen ? 'rotate-180' : ''}`} />
                  </button>
                  
                  {userDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
                      <div className="px-4 py-3 border-b border-gray-100">
                        <p className="text-sm font-semibold text-gray-900">{headerInfo?.name || userData?.name || 'User'}</p>
                        <div className="flex items-center mt-1 text-sm text-gray-500">
                          <Phone className="h-4 w-4 mr-1" />
                          <span>{headerInfo?.mobile || userData?.mobile || '9444926120'}</span>
                        </div>
                      </div>
                      <button 
                        onClick={handleLogout}
                        className="flex w-full items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        <span>Logout</span>
                      </button>
                    </div>
                  )}
                </div>
              </nav>

              <div className="hidden lg:flex items-center space-x-6 pl-8 border-l border-black/20">
                <div className="text-black text-sm font-medium">
                  {formatTime(currentTime)}
                </div>
                <div className="text-black text-sm font-medium">
                  {currentTime.toLocaleDateString('en-US', { 
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  })}
                </div>
              </div>
            </div>

            {/* Mobile menu button remains unchanged */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 text-black hover:text-black/80 transition-colors focus:outline-none"
              aria-label="Menu"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu with updated user section */}
      <div 
        className={`md:hidden fixed inset-0 bg-gray-800/50 backdrop-blur-sm transition-opacity duration-300 z-50 ${
          isMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMenuOpen(false)}
      >
        <div 
          className={`absolute right-0 top-0 h-full w-72 bg-white shadow-xl transform transition-transform duration-300 ease-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          onClick={e => e.stopPropagation()}
        >
          {/* Mobile header remains unchanged */}
          <div className="bg-[#75d1e3] text-black p-4 flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <img src="/images/tnlogo.png" alt="Logo" className="h-8 w-8" />
              <span className="font-semibold">TN e-Governance</span>
            </div>
            <button
              onClick={() => setIsMenuOpen(false)}
              className="p-2 hover:bg-[#67c3d5] rounded-full transition-colors focus:outline-none"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          {/* Time display remains unchanged */}
          <div className="bg-gray-50 p-4 border-b">
            <div className="flex justify-between items-center text-sm text-black">
              <div className="font-medium">{formatTime(currentTime)}</div>
              <div className="font-medium">
                {currentTime.toLocaleDateString('en-US', { 
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric'
                })}
              </div>
            </div>
          </div>

          <div className="p-4 space-y-2">
            <NavButton icon={Home} label="Home" path="/home" isMobile />
            {headerInfo && <>
              {isAuthorizedRole(headerInfo.role) && (
                <NavButton 
                  icon={ClipboardList} 
                  label="Survey" 
                  path="/filters" 
                  disabled={!isSurveyEnabled}
                  onClick={() => handleNavigation('/filters', surveyParams)}
                  isMobile
                />
              )}
              <NavButton icon={BarChart2} label="Reports" path="/generatereport" isMobile />
            </>}

            <div className="border-t border-gray-200 my-4 pt-4">
              {/* Updated mobile user info section */}
              <div className="px-4 py-3 bg-gray-50 rounded-lg mb-2">
                <div className="flex items-center text-sm text-black">
                  <UserCircle className="h-5 w-5 mr-2" />
                  <span className="font-medium">{headerInfo?.name || userData?.name || 'User'}</span>
                </div>
                <div className="flex items-center mt-1 text-sm text-gray-500 ml-7">
                  <Phone className="h-4 w-4 mr-1" />
                  <span>{headerInfo?.mobile || userData?.mobile || '9444926120'}</span>
                </div>
              </div>
              <button 
                onClick={handleLogout}
                className="w-full flex items-center px-4 py-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              >
                <LogOut className="h-5 w-5 mr-3" />
                <span className="font-medium">Logout</span>
              </button>
            </div>
          </div>

          <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-50 border-t text-center">
            <div className="text-sm text-black">Government of Tamil Nadu</div>
            <div className="text-xs text-black/70">e-Governance Portal</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;