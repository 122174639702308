import React, { useState, useEffect, } from 'react';
import { useParams, useNavigate,useLocation} from 'react-router-dom';
import { ArrowLeft, User, Check, Phone, Building, LayoutDashboard, MapPin, Camera, X, ChevronDown, ChevronUp } from 'lucide-react';
import api from '../apiConfig/api';

import PhotoLocationCapture from '../components/photolocationcapture';

// Keep DetailSection Component exactly the same
// Update DetailSection Component
const DetailSection = ({ title, children }) => (
  <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-100 mb-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-800 mb-6 flex items-center gap-3">
      <div className="h-8 w-1 bg-sky-500 rounded-full"></div> {/* Changed from bg-blue-500 to bg-sky-500 */}
      {title}
    </h3>
    <div className="animate-fadeIn">
      {children}
    </div>
  </div>
);

// Keep DetailItem Component exactly the same
const DetailItem = ({ label, value, icon: Icon }) => (
  <div className="p-5 bg-white rounded-xl border border-gray-200 hover:border-blue-500 transition-all duration-300 group">
    <div className="flex items-center gap-3 mb-2">
      {Icon && <Icon className="h-5 w-5 text-sky-500 group-hover:scale-110 transition-transform duration-300" />}
      <label className="text-sm font-semibold text-gray-600">{label}</label>
    </div>
    <p className="text-lg font-semibold text-gray-900 pl-8">{value}</p>
  </div>
);
const PropertyDetailsForm = ({ 
  onChange, 
  data, 
  propertyPhotos, 
  setPropertyPhotos, 
  previousArea, 
  previousUsage,
  entityDetails,
  setEntityDetails,
  propertyData,
  commercialArea,
  setCommercialArea,
  hasBusiness,
  setHasBusiness,
  businessLocation,
  setBusinessLocation,
  businessWardNo,
  setBusinessWardNo,
  businessAddress,
  setBusinessAddress,
  businessName,
  setBusinessName,
  businessType,
  setBusinessType,
  externalLocationType,
  setExternalLocationType
}) => {
  const [ebNumberError, setEbNumberError] = useState('');
  const [showEbError, setShowEbError] = useState(false);
  const [commercialEntities, setCommercialEntities] = useState(0);
  const [tradeLicenses, setTradeLicenses] = useState([]);
  const [isLoadingLicenses, setIsLoadingLicenses] = useState(false);
  
  const buildingUsageOptions = [
    { value: "residential", label: "Residential" },
    { value: "commercial", label: "Commercial" },
    { value: "government", label: "Government" },
    { value: "educational", label: "Educational" },
    { value: "industrial", label: "Industrial" },
    { value: "mixed", label: "Mixed" }
  ];

  const externalLocationTypes = [
    { value: "villagePanchayat", label: "Village Panchayat" },
    { value: "townPanchayat", label: "Town Panchayat" },
    { value: "municipality", label: "Municipality" },
    { value: "corporation", label: "Corporation" },
    { value: "notKnown", label: "Not Known" }
  ];

  useEffect(() => {
    const orgId = propertyData?.organization_id;
    const wardId = propertyData?.ward_id;
    
    if ((data.buildingUsage === 'commercial' || data.buildingUsage === 'mixed')) {
      fetchTradeLicenses(orgId, wardId);
    }
  }, [propertyData?.organization_id, propertyData?.ward_id, data.buildingUsage]);

  const fetchTradeLicenses = async () => {
    setIsLoadingLicenses(true);
    try {
      const token = localStorage.getItem('final');  
      const orgId = localStorage.getItem('org_id');
  
      if (!token) {
        console.error('Authentication token not found');
        return;
      }
  
      const wardId = propertyData?.ward_id;
  
      if (!orgId || !wardId) {
        console.error('Missing organization code or ward ID');
        return;
      }
  
      const response = await api.get(`/user/proftax?orgid=${orgId}&wid=${wardId}`, {
        headers: {
          'Authorization': token
        }
      });
  
      console.log('Trade License Response:', response.data); // Debug log
  
      const data = response.data;
      if (Array.isArray(data)) {
        console.log('Trade Licenses:', data); // Debug log
        setTradeLicenses(data);
      } else if (data.data && Array.isArray(data.data)) {
        // If the data is nested under a 'data' property
        console.log('Trade Licenses:', data.data); // Debug log
        setTradeLicenses(data.data);
      } else {
        console.log('No valid trade license data found:', data); // Debug log
        setTradeLicenses([]);
      }
  
    } catch (error) {
      console.error('Error fetching trade licenses:', error);
      setTradeLicenses([]);
    } finally {
      setIsLoadingLicenses(false);
    }
  };
  const validateEbNumber = (value) => {
    if (!value) {
      setEbNumberError('Please enter EB number');
      return false;
    }
    if (value.length !== 12) {
      setEbNumberError('EB number must be 12 digits');
      return false;
    }
    setEbNumberError('');
    return true;
  };

  const handleEmployeeCountChange = (entityIndex, rowKey, value) => {
    const newDetails = [...entityDetails];
    const numValue = value === '' ? 0 : parseInt(value.replace(/^0+/, '')) || 0;
    if (!newDetails[entityIndex].employeeCounts) {
      newDetails[entityIndex].employeeCounts = {};
    }
    newDetails[entityIndex].employeeCounts[rowKey] = numValue;
    setEntityDetails(newDetails);
  };

  const calculateTax = (employeeCount, taxRate) => {
    if (!employeeCount || !taxRate) return 0;
    return employeeCount * taxRate;
  };

  return (
    <div className="mb-6 mt-10">
      <h4 className="text-xl font-bold text-gray-800 mb-6 flex items-center gap-3">
        <Building className="h-6 w-6 text-sky-500" />
        <span className="relative">Property Details</span>
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
        <div className="md:col-span-1">
          <label className="block text-sm font-semibold text-gray-700 mb-0.5">Usage</label>
          <div className="flex rounded-xl overflow-hidden border border-gray-200">
            <div className="flex-1 border-r border-gray-200">
              <div className="px-2 py-0.5 bg-gray-50 border-b border-gray-200">
                <span className="text-xs text-gray-600">As per Records</span>
              </div>
              <input
                type="text"
                className="w-full p-1.5 bg-gray-50 cursor-not-allowed"
                value={previousUsage || ''}
                disabled
              />
            </div>
            <div className="flex-1">
              <div className="px-2 py-0.5 bg-gray-50 border-b border-gray-200">
                <span className="text-xs text-gray-600">As Observed</span>
              </div>
              <select
                className="w-full p-1.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                value={data.buildingUsage || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange('buildingUsage', value);
                  setCommercialEntities(0);
                  setCommercialArea('');
                  setEntityDetails([]);
                  if (value !== 'residential') {
                    setHasBusiness('no');
                    setBusinessLocation('withinULB');
                    setBusinessWardNo('');
                    setBusinessAddress('');
                    setBusinessName('');
                    setBusinessType('');
                    setExternalLocationType('');
                  }
                }}
                required
              >
                <option value="">Select Usage</option>
                {buildingUsageOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="md:col-span-1">
          <label className="block text-sm font-semibold text-gray-700 mb-0.5">Build Area</label>
          <div className="flex rounded-xl overflow-hidden border border-gray-200">
            <div className="flex-1 border-r border-gray-200">
              <div className="px-2 py-0.5 bg-gray-50 border-b border-gray-200">
                <span className="text-xs text-gray-600">As per Records</span>
              </div>
              <input
                type="text"
                className="w-full p-1.5 bg-gray-50 cursor-not-allowed"
                value={previousArea || ''}
                disabled
              />
            </div>
            <div className="flex-1">
              <div className="px-2 py-0.5 bg-gray-50 border-b border-gray-200">
                <span className="text-xs text-gray-600">As Observed</span>
              </div>
              <input
                type="number"
                className="w-full p-1.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                value={data.floorArea || ''}
                onChange={(e) => onChange('floorArea', e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>

      {data.buildingUsage === 'residential' && (
        <div className="mt-8 border-t border-gray-200 pt-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Does the applicant have business elsewhere?
              </label>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="hasBusiness"
                    value="yes"
                    checked={hasBusiness === 'yes'}
                    onChange={(e) => setHasBusiness(e.target.value)}
                    className="mr-2"
                  />
                  Yes
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="hasBusiness"
                    value="no"
                    checked={hasBusiness === 'no'}
                    onChange={(e) => setHasBusiness(e.target.value)}
                    className="mr-2"
                  />
                  No
                </label>
              </div>
            </div>

            {hasBusiness === 'yes' && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Is the business within ULB?
                  </label>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="businessLocation"
                        value="withinULB"
                        checked={businessLocation === 'withinULB'}
                        onChange={(e) => setBusinessLocation(e.target.value)}
                        className="mr-2"
                      />
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="businessLocation"
                        value="outsideULB"
                        checked={businessLocation === 'outsideULB'}
                        onChange={(e) => setBusinessLocation(e.target.value)}
                        className="mr-2"
                      />
                      No
                    </label>
                  </div>
                </div>

                {businessLocation === 'withinULB' ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Ward No
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={businessWardNo}
                        onChange={(e) => setBusinessWardNo(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Business Name
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Business Type
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={businessType}
                        onChange={(e) => setBusinessType(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Address
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={businessAddress}
                        onChange={(e) => setBusinessAddress(e.target.value)}
                      />
                    </div>
                  </div>
                ) : businessLocation === 'outsideULB' && (
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Location Type
                      </label>
                      <select
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={externalLocationType}
                        onChange={(e) => setExternalLocationType(e.target.value)}
                      >
                        <option value="">Select Location Type</option>
                        {externalLocationTypes.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Business Address
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={businessAddress}
                        onChange={(e) => setBusinessAddress(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {(data.buildingUsage === 'commercial' || data.buildingUsage === 'mixed') && (
        <div className="mt-8 border-t border-gray-200">
          <div className="pt-6 space-y-6">
          {data.buildingUsage === 'mixed' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Commercial Area (in sq.ft)
                  </label>
                  <div>
                    <input
                      type="number"
                      className={`w-full md:w-[80%] p-4 border ${
                        parseFloat(commercialArea) > parseFloat(data.floorArea || 0) 
                          ? 'border-red-500 focus:ring-red-500 focus:border-red-500' 
                          : 'border-gray-200 focus:ring-blue-500 focus:border-blue-500'
                      } rounded-xl`}
                      value={commercialArea}
                      onChange={(e) => {
                        const value = e.target.value;
                        setCommercialArea(value);
                      }}
                      required
                    />
                    {parseFloat(commercialArea) > parseFloat(data.floorArea || 0) && (
                      <p className="mt-2 text-sm text-red-600">
                        Commercial area cannot be higher than the total build area ({data.floorArea} sq.ft)
                      </p>
                    )}
                  </div>
                </div>
                <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Number of Commercial Entities
                  </label>
                  <select
                    className="w-full md:w-[80%] p-4 border border-gray-200 rounded-xl"
                    value={commercialEntities}
                    onChange={(e) => {
                      const count = parseInt(e.target.value);
                      setCommercialEntities(count);
                      setEntityDetails(Array(count).fill().map(() => ({
                        hasLicense: true,
                        licenseNo: '',
                        firmName: '',
                        ownerName: '',
                        employeeCounts: {
                          row1: 0, row2: 0, row3: 0, row4: 0, row5: 0, row6: 0
                        }
                      })));
                    }}
                    required
                  >
                    <option value="">Select number of entities</option>
                    {[...Array(20)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Number of Commercial Entities
                </label>
                <select
                  className="w-full md:w-[30%] p-4 border border-gray-200 rounded-xl"
                  value={commercialEntities}
                  onChange={(e) => {
                    const count = parseInt(e.target.value);
                    setCommercialEntities(count);
                    setEntityDetails(Array(count).fill().map(() => ({
                      hasLicense: true,
                      licenseNo: '',
                      firmName: '',
                      ownerName: '',
                      employeeCounts: {
                        row1: 0, row2: 0, row3: 0, row4: 0, row5: 0, row6: 0
                      }
                    })));
                  }}
                  required
                >
                  <option value="">Select number of entities</option>
                  {[...Array(20)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            )}

            {commercialEntities > 0 && entityDetails.map((entity, index) => (
              <div key={index} className="border p-6 rounded-lg space-y-4">
                <h5 className="font-semibold">Entity {index + 1}/{commercialEntities}</h5>
                <div className="w-full md:w-[50%] space-y-4">
                  <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-semibold text-gray-700">Professional Tax No Available</label>
                    <button
                      onClick={() => {
                        const newDetails = [...entityDetails];
                        newDetails[index] = {
                          ...newDetails[index],
                          hasLicense: !entity.hasLicense,
                          licenseNo: ''
                        };
                        setEntityDetails(newDetails);
                      }}
                      className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      style={{ backgroundColor: entity.hasLicense ? '#10B981' : '#D1D5DB' }}
                    >
                      <span className="sr-only">Toggle license availability</span>
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out ${
                          entity.hasLicense ? 'translate-x-6' : 'translate-x-1'
                        }`}
                      />
                    </button>
                  </div>

                  {entity.hasLicense && (
                    <div className="flex-1 relative">
<div className="relative">
  <input
    type="text"
    className="w-full p-4 border border-gray-200 rounded-xl"
    value={entity.licenseNo || ''}
    onChange={(e) => {
      const value = e.target.value;
      const newDetails = [...entityDetails];
      newDetails[index] = {
        ...newDetails[index],
        hasLicense: true,
        licenseNo: value,
        showDropdown: true // Add this to control dropdown visibility
      };
      setEntityDetails(newDetails);
    }}
    placeholder="Search Tax No"
    disabled={isLoadingLicenses}
  />
  {entity.showDropdown && tradeLicenses.length > 0 && (
    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg max-h-48 overflow-y-auto">
      {tradeLicenses
        .filter(tax => {
          const licenseNo = (tax.prof_tax_no || tax.ProfTaxAssesmentNo || tax.taxNo || '').toString();
          return licenseNo.toLowerCase().includes((entity.licenseNo || '').toLowerCase());
        })
        .map((tax, idx) => {
          const licenseNo = tax.prof_tax_no || tax.ProfTaxAssesmentNo || tax.taxNo;
          return (
            <div
              key={idx}
              className="p-3 hover:bg-gray-100 cursor-pointer text-sm"
              onClick={() => {
                const newDetails = [...entityDetails];
                newDetails[index] = {
                  ...newDetails[index],
                  hasLicense: true,
                  licenseNo: licenseNo,
                  showDropdown: false
                };
                setEntityDetails(newDetails);
                
                // Update the main propertyDetails state
                onChange('hasProfessionalTax', true);
                onChange('professionalTaxId', licenseNo);
              }}
            >
              {licenseNo}
            </div>
          );
        })}
    </div>
  )}
</div>
                      <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none h-5 w-5" />
                    </div>
                  )}

                  {isLoadingLicenses && (
                    <div className="flex items-center gap-2 text-blue-600">
                      <div className="animate-spin h-4 w-4 border-2 border-blue-600 border-t-transparent rounded-full"></div>
                      <span className="text-sm">Loading Tax No's...</span>
                    </div>
                  )}
                </div>

                {!entity.hasLicense && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Name of Firm
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={entity.firmName || ''}
                        onChange={(e) => {
                          const newDetails = [...entityDetails];
                          newDetails[index] = {
                            ...newDetails[index],
                            firmName: e.target.value
                          };
                          setEntityDetails(newDetails);
                        }}
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Owner Name
                      </label>
                      <input
                        type="text"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={entity.ownerName || ''}
                        onChange={(e) => {
                          const newDetails = [...entityDetails];
                          newDetails[index] = {
                            ...newDetails[index],
                            ownerName: e.target.value
                          };
                          setEntityDetails(newDetails);
                        }}
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold text-gray-700 mb-2">
                        Mobile Number
                      </label>
                      <input
                        type="tel"
                        className="w-full p-4 border border-gray-200 rounded-xl"
                        value={entity.mobileNumber || ''}
                        onChange={(e) => {
                          const newDetails = [...entityDetails];
                          newDetails[index] = {
                            ...newDetails[index],
                            mobileNumber: e.target.value
                          };
                          setEntityDetails(newDetails);
                        }}
                        required
                      />
                    </div>
                  </div>
                )}

                {/* Employee Count Table */}
                <div className="w-full lg:w-1/2">
                  <div className="hidden lg:block">
                    <div className="overflow-x-auto">
                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500">
                              Half-Yearly Income Range
                            </th>
                            <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">
                              Number of Employees
                            </th>
                            <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">
                              Tax Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          <tr>
                            <td className="px-3 py-2 text-xs text-gray-900">Up to ₹21,000 (₹0)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row1 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row1', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">Nil</td>
                          </tr>
                          <tr className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-900">₹21,000-₹31,000 (₹135)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row2 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row2', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row2, 135)}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-3 py-2 text-xs text-gray-900">₹31,001-₹45,000 (₹315)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row3 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row3', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row3, 315)}
                            </td>
                          </tr>
                          <tr className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-900">₹45,001-₹60,000 (₹690)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row4 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row4', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row4, 690)}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-3 py-2 text-xs text-gray-900">₹60,001-₹75,000 (₹1,025)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row5 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row5', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row5, 1025)}
                            </td>
                          </tr>
                          <tr className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-900">Above ₹75,001 (₹1,250)</td>
                            <td className="px-3 py-2 text-right">
                              <input
                                type="number"
                                min="0"
                                className="w-20 p-1 border border-gray-200 rounded text-right"
                                value={entity.employeeCounts?.row6 || 0}
                                onChange={(e) => handleEmployeeCountChange(index, 'row6', e.target.value)}
                              />
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row6, 1250)}
                            </td>
                          </tr>
                          <tr className="bg-gray-100 font-medium">
                            <td className="px-3 py-2 text-xs text-gray-900">Total</td>
                            <td className="px-3 py-2 text-xs text-right">
                              {Object.values(entity.employeeCounts || {}).reduce((sum, count) => sum + (parseInt(count) || 0), 0)}
                            </td>
                            <td className="px-3 py-2 text-xs text-right">
                              ₹{calculateTax(entity.employeeCounts?.row2, 135) +
                                 calculateTax(entity.employeeCounts?.row3, 315) +
                                 calculateTax(entity.employeeCounts?.row4, 690) +
                                 calculateTax(entity.employeeCounts?.row5, 1025) +
                                 calculateTax(entity.employeeCounts?.row6, 1250)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

            {/* Mobile View */}
            <div className="block lg:hidden space-y-2 p-2">
                    {[
                      { label: "Up to ₹21,000", unitRate: "₹0", row: "row1", rate: 0 },
                      { label: "₹21,000 - ₹31,000", unitRate: "₹135", row: "row2", rate: 135 },
                      { label: "₹31,001 - ₹45,000", unitRate: "₹315", row: "row3", rate: 315 },
                      { label: "₹45,001 - ₹60,000", unitRate: "₹690", row: "row4", rate: 690 },
                      { label: "₹60,001 - ₹75,000", unitRate: "₹1,025", row: "row5", rate: 1025 },
                      { label: "Above ₹75,001", unitRate: "₹1,250", row: "row6", rate: 1250 }
                    ].map((range, idx) => (
                      <div key={idx} className="bg-white p-3 rounded-lg shadow-sm border border-gray-200">
                        <div className="text-xs font-medium text-gray-900 mb-3">
                          {range.label} ({range.unitRate})
                        </div>
                        <div className="flex flex-col space-y-2">
                          <div className="flex justify-between items-center">
                            <label className="text-xs text-gray-500">Employees</label>
                            <input
                              type="number"
                              min="0"
                              className="w-16 p-1 border border-gray-200 rounded text-right text-xs"
                              value={entity.employeeCounts?.[range.row] || 0}
                              onChange={(e) => handleEmployeeCountChange(index, range.row, e.target.value)}
                            />
                          </div>
                          <div className="flex justify-between items-center">
                            <label className="text-xs text-gray-500">Amount</label>
                            <span className="text-xs font-medium text-gray-900">
                              {range.rate ? `₹${calculateTax(entity.employeeCounts?.[range.row], range.rate)}` : 'Nil'}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* Summary Card */}
                    <div className="bg-blue-50 p-3 rounded-lg border border-blue-100">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-xs font-medium text-gray-700">Total Employees</span>
                        <span className="text-xs font-semibold text-blue-600">
                          {Object.values(entity.employeeCounts || {}).reduce((sum, count) => sum + (parseInt(count) || 0), 0)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-xs font-medium text-gray-700">Total Tax</span>
                        <span className="text-xs font-semibold text-blue-600">
                          ₹{(
                            calculateTax(entity.employeeCounts?.row2, 135) +
                            calculateTax(entity.employeeCounts?.row3, 315) +
                            calculateTax(entity.employeeCounts?.row4, 690) +
                            calculateTax(entity.employeeCounts?.row5, 1025) +
                            calculateTax(entity.employeeCounts?.row6, 1250)
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-8">
        <label className="block text-sm font-semibold text-gray-700 mb-4">
          Property Photos with Location (Max 3)
        </label>
        <PhotoLocationCapture 
          onPhotoCaptured={(photos) => {
            console.log('Photos captured:', photos);
            setPropertyPhotos(photos);
          }}
        />
      </div>
    </div>
  );
};



const VerificationPage = () => {
  const { id, asst_ref } = useParams();
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState(null);
  const [ownerVerified, setOwnerVerified] = useState(true);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [totalArea, setTotalArea] = useState('');
  const [buildingStructure, setBuildingStructure] = useState('ac');
  const [buildingType, setBuildingType] = useState('independent');
  const [entityDetails, setEntityDetails] = useState([]);
  const [apartmentFloor, setApartmentFloor] = useState('');
  const [commercialArea, setCommercialArea] = useState('');
  const location = useLocation();
  const isFromAssets = sessionStorage.getItem('fromAssets') === 'true';
  const [propertyDetails, setPropertyDetails] = useState({
    floorArea: '',
    buildingUsage: '',
    ebNumber: '',
    hasProfessionalTax: true,
    professionalTaxId: ''
  });

  // Add new state variables for residential business
  const [hasBusiness, setHasBusiness] = useState('no');
  const [businessLocation, setBusinessLocation] = useState('withinULB');
  const [businessWardNo, setBusinessWardNo] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [externalLocationType, setExternalLocationType] = useState('');

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [showMobileError, setShowMobileError] = useState(false);
  const [totalFloors, setTotalFloors] = useState('');
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newOwnerName, setNewOwnerName] = useState('');
  const [isBuilding, setIsBuilding] = useState(true);
  const [currentUsage, setCurrentUsage] = useState('');
  const [propertyPhotos, setPropertyPhotos] = useState([]);

  
  const [formData, setFormData] = useState({
    zoneId: '',
    wardId: '',
    areaId: '',
    localityId: '',
  });
  const validateMobileNumber = (number) => {
    const isValid = /^[6-9]\d{9}$/.test(number);
    setIsMobileValid(isValid);
    return isValid;
  };
  const [isLoadingLicenses, setIsLoadingLicenses] = useState(false);
  const [tradeLicenses, setTradeLicenses] = useState([]);
  const fetchTradeLicenses = async () => {
    setIsLoadingLicenses(true);
    try {
      const token = localStorage.getItem('final');  
      const orgId = localStorage.getItem('org_id');
  
      if (!token) {
        console.error('Authentication token not found');
        return;
      }
  
      const wardId = propertyData?.ward_id;
  
      if (!orgId || !wardId) {
        console.error('Missing organization code or ward ID');
        return;
      }
  
      const response = await api.get(`/user/proftax?orgid=${orgId}&wid=${wardId}`, {
        headers: {
          'Authorization': token
        }
      });
  
      console.log('Trade License Response:', response.data); // Debug log
  
      const data = response.data;
      if (Array.isArray(data)) {
        console.log('Trade Licenses:', data); // Debug log
        setTradeLicenses(data);
      } else if (data.data && Array.isArray(data.data)) {
        // If the data is nested under a 'data' property
        console.log('Trade Licenses:', data.data); // Debug log
        setTradeLicenses(data.data);
      } else {
        console.log('No valid trade license data found:', data); // Debug log
        setTradeLicenses([]);
      }
  
    } catch (error) {
      console.error('Error fetching trade licenses:', error);
      setTradeLicenses([]);
    } finally {
      setIsLoadingLicenses(false);
    }
  };
  const calculateTax = (employeeCount, taxRate) => {
    if (!employeeCount || !taxRate) return 0;
    return employeeCount * taxRate;
  };
  

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        setLoading(true);
        const locationState = location.state;
  
        // Check for required params
        if (!locationState?.orgid || !locationState?.wid || !locationState?.asstid) {
          console.error('Missing required parameters:', locationState);
          alert('Missing required parameters for property details');
          navigate(-1);
          return;
        }
  
        const response = await api.get('/asset/detail', {
          params: {
            orgid: locationState.orgid,
            wid: locationState.wid,
            asstid: locationState.asstid
          }
        });
  
        if (response.data?.data?.[0]) {
          const property = response.data.data[0];
          const floorDetails = property.FloorDetails?.[0];
          const totalBuildArea = property.FloorDetails?.reduce((sum, floor) => {
            return sum + (parseFloat(floor.Area) || 0);
          }, 0) || '';
          setPropertyData({
            area_name: property.OrgName,
            zone_name: property.ZoneName,
            ward_name: property.WardName,
            street_name: property.StreetName,
            asst_ref: property.AssesmentNo,
            new_door: floorDetails?.doorNo || '',
            owner: property.OwnerName,
            build_area: totalBuildArea.toString(),
            zone_id: property.ZoneID,
            ward_id: property.WardID,
            area_id: property.LocalityID,
            loc_id: property.LocalityID,
            organization_id: property.OrgID,
            usage_type: floorDetails?.FloorUsage || '',
            eb_number: floorDetails?.EBNumber || '',
            mobile_number: property.MobileNo || ''
          });
  
          setFormData({
            zoneId: property.ZoneID,
            wardId: property.WardID,
            areaId: property.LocalityID,
            localityId: property.LocalityID
          });
  
          setPropertyDetails(prev => ({
            ...prev,
            floorArea: '',
            buildingUsage: 'residential',
            ebNumber: floorDetails?.EBNumber || ''
          }));
  
          setMobileNumber(property.MobileNo || '');
          if (property.MobileNo) {
            validateMobileNumber(property.MobileNo);
          }
  
          setTotalArea(floorDetails?.Area || '');
          setBuildingType(property.BuildingType?.toLowerCase() || 'independent');
          setTotalFloors(property.TotalFloor || '');
          
          const floorMatch = floorDetails?.FloorNo?.match(/\d+/);
          setApartmentFloor(floorMatch ? floorMatch[0] : '0');
          
          setBuildingStructure(floorDetails?.ConstructionType?.toLowerCase() || 'rcc');
  
        } else {
          throw new Error('No property data found');
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
        alert(error.message || 'Error loading property details');
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
  
    // Changed condition here to check for asstid instead of asstno
    if (location.state?.orgid && location.state?.wid && location.state?.asstid) {
      fetchPropertyDetails();
    } else {
      setLoading(false);
      console.error('Missing navigation state params');
      alert('Missing required parameters');
      navigate(-1);
    }
  }, [location.state, navigate]);

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      alert('You can only upload up to 3 photos');
      return;
    }
    setPropertyPhotos(files);
  };

  const handleOwnerUpdate = () => {
    if (newOwnerName.trim()) {
      // Here you can add the API call to update the owner name
      console.log('Updating owner name to:', newOwnerName);
      // After successful update:
      setOwnerVerified(true);
    }
  };

  const removePhoto = (index) => {
    const updatedPhotos = [...propertyPhotos];
    updatedPhotos.splice(index, 1);
    setPropertyPhotos(updatedPhotos);
  };

 // Update the owner verification section in VerificationPage component

// Update the validateForm function
const validateForm = () => {
  const newErrors = {};
  let errorMessage = '';

  // Owner verification check
  if (!ownerVerified && !newOwnerName.trim()) {
    errorMessage += '- Please enter the new owner name\n';
  }

  // Mobile Number validation
  if (!mobileNumber || !validateMobileNumber(mobileNumber)) {
    errorMessage += '- Please enter a valid 10-digit mobile number\n';
  }

  if (isBuilding) {
    // Common Building Validations
    if (!propertyDetails.floorArea) {
      errorMessage += '- Build Area as Observed is required\n';
    }

    if (!propertyDetails.buildingUsage) {
      errorMessage += '- Building usage is required\n';
    }


    // Enhanced Professional Tax Validation
// Inside validateForm function
// Replace the Professional Tax Validation section with:

// Enhanced Professional Tax Validation
if (propertyDetails.buildingUsage === 'commercial' || propertyDetails.buildingUsage === 'mixed') {
  const invalidEntities = entityDetails.filter(entity => {
    if (entity.hasLicense) {
      // Check if license number is provided when hasLicense is true
      return !entity.licenseNo;
    } else {
      // Check if required fields are filled when hasLicense is false
      return !entity.firmName || !entity.ownerName || !entity.mobileNumber;
    }
  });

  if (invalidEntities.length > 0) {
    errorMessage += '- Please provide ';
    if (invalidEntities.some(e => e.hasLicense)) {
      errorMessage += 'Professional Tax ID for entities with tax numbers';
    } else {
      errorMessage += 'firm details (name, owner, mobile) for entities without tax numbers';
    }
    errorMessage += '\n';
  }
}

    // Building Type validation
    if (!buildingType) {
      errorMessage += '- Please select a building type\n';
    }

    // Apartment Specific Validations
    if (buildingType === 'apartment') {
      if (!totalFloors) {
        errorMessage += '- Please select total number of floors\n';
      }
      
      if (!apartmentFloor && apartmentFloor !== '0') {
        errorMessage += '- Please select the floor number\n';
      }

      // Roof Structure validation for top floor
      if (apartmentFloor === totalFloors && !buildingStructure) {
        errorMessage += '- Roof structure is required for top floor\n';
      }
    }

    // Independent or Row House Validations
    if (buildingType === 'independent' || buildingType === 'row_house') {
      if (!buildingStructure) {
        errorMessage += '- Roof structure is required\n';
      }
    }

    // Photo validation for all building types
    if (!propertyPhotos || propertyPhotos.length === 0) {
      errorMessage += '- At least one property photo is required\n';
    }
  } else {
    // Non-Building Validations
    if (!currentUsage) {
      errorMessage += '- Please select current usage\n';
    }

    if (!propertyPhotos || propertyPhotos.length === 0) {
      errorMessage += '- At least one property photo is required\n';
    }
  }

  // Handle errors
  if (errorMessage) {
    alert('Please fix the following issues:\n' + errorMessage);
    setErrors(newErrors);
    return true; // Return true to indicate validation failed
  }

  // Clear errors if everything is valid
  setErrors({});
  return false; // Return false to indicate validation passed
};
  const handleBack = () => navigate(-1);
  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
  
    try {
      setIsSubmitting(true);
      const userData = JSON.parse(localStorage.getItem('userData'));
      const token = userData?.authToken;
  
      if (!token) {
        throw new Error('Authentication token not found');
      }
  
      // Separate location data from first photo
      const primaryLocation = propertyPhotos[0]?.location || null;
      const photoInfo = propertyPhotos.map(photo => ({
        path: photo.path
      }));
  
      // Location payload
      const locationPayload = primaryLocation ? {
        latitude: primaryLocation.lat,
        longitude: primaryLocation.lng
      } : null;
  
      // Create commercial data array for both commercial and mixed usage
      const commercialData = entityDetails.map(entity => {
        // Calculate total employees and tax for this entity
        const totalEmployees = Object.values(entity.employeeCounts || {})
          .reduce((sum, count) => sum + (parseInt(count) || 0), 0);
  
        const totalTax = Object.entries(entity.employeeCounts || {})
          .reduce((sum, [key, count]) => {
            const rowNum = key.replace('row', '');
            const taxRate = getTaxRateForRow(rowNum);
            return sum + calculateTax(parseInt(count) || 0, taxRate);
          }, 0);
  
        // Create employee details object
        const empDetails = {};
        Object.entries(entity.employeeCounts || {}).forEach(([key, count]) => {
          if (parseInt(count) > 0) {
            const rowNum = key.replace('row', '');
            empDetails[`range_${rowNum}`] = {
              range: getRangeForRow(rowNum),
              count: parseInt(count),
              tax: calculateTax(parseInt(count), getTaxRateForRow(rowNum))
            };
          }
        });
  
        // Return either professional tax data or firm details
        if (entity.hasLicense) {
          return {
            has_prof_tax: true,
            prof_tax_no: entity.licenseNo?.toString() || '',
            firm_name: '',
            owner_name: '',
            mobile_number: '',
            total_employees: totalEmployees,
            total_tax: totalTax,
            emp_details: empDetails
          };
        } else {
          return {
            has_prof_tax: false,
            prof_tax_no: '',
            firm_name: entity.firmName || '',
            owner_name: entity.ownerName || '',
            mobile_number: entity.mobileNumber || '',
            total_employees: totalEmployees,
            total_tax: totalTax,
            emp_details: empDetails
          };
        }
      });
  
      // Calculate total professional tax
      const totalProfessionalTax = commercialData.reduce((total, entity) => 
        total + (entity.total_tax || 0), 0);
  
      let residentialBusinessData;
      
      if (propertyDetails.buildingUsage === 'residential') {
        if (hasBusiness === 'yes') {
          if (businessLocation === 'withinULB') {
            residentialBusinessData = {
              has_business: true,
              business_location: {
                is_within_ulb: true,
                ward_no: businessWardNo || '',
                business_name: businessName || '',
                business_type: businessType || '',
                address: businessAddress || ''
              }
            };
          } else {
            residentialBusinessData = {
              has_business: true,
              business_location: {
                is_within_ulb: false,
                location_type: externalLocationType || '',
                address: businessAddress || ''
              }
            };
          }
        } else {
          residentialBusinessData = {
            has_business: false,
            business_location: null
          };
        }
      }
  
      const payload = {
        asst_det_id: parseInt(id),
        owner_det: {
          name: (ownerVerified ? propertyData?.owner : newOwnerName)?.trim() || '',
          mobile: mobileNumber?.toString()?.trim() || ''
        },
        str_det: {
          type: buildingType === 'independent' ? 'Individual House' :
                buildingType === 'row_house' ? 'Row House' : 'Apartment',
          floors: totalFloors ? `${totalFloors} Floors` : '0 Floors',
          prop_floor: apartmentFloor === '0' ? 'Ground Floor' :
                      apartmentFloor ? `${apartmentFloor}${
                        apartmentFloor === '1' ? 'st' :
                        apartmentFloor === '2' ? 'nd' :
                        apartmentFloor === '3' ? 'rd' : 'th'} Floor` : 'Ground Floor',
          structure: buildingStructure.toUpperCase(),
        },
        area: (propertyDetails?.floorArea || '0').toString(),
        usage: (propertyDetails?.buildingUsage || '').charAt(0).toUpperCase() +
               (propertyDetails?.buildingUsage || '').slice(1).toLowerCase(),
        eb_num: (propertyDetails?.ebNumber || '').toString(),
        prof_tax: {
          has_tax: ['commercial', 'mixed'].includes(propertyDetails?.buildingUsage?.toLowerCase()),
          total_tax: entityDetails.reduce((total, entity) => {
            return total + Object.entries(entity.employeeCounts || {}).reduce((sum, [key, count]) => {
              const rowNum = key.replace('row', '');
              const taxRate = getTaxRateForRow(rowNum);
              return sum + (parseInt(count) || 0) * taxRate;
            }, 0);
          }, 0),
          entities: entityDetails.map(entity => ({
            has_prof_tax: entity.hasLicense || false,
            prof_tax_no: entity.licenseNo || '',
            firm_name: entity.hasLicense ? '' : (entity.firmName || ''),
            owner_name: entity.hasLicense ? '' : (entity.ownerName || ''),
            mobile_number: entity.hasLicense ? '' : (entity.mobileNumber || ''),
            total_employees: Object.values(entity.employeeCounts || {})
              .reduce((sum, count) => sum + (parseInt(count) || 0), 0),
            total_tax: Object.entries(entity.employeeCounts || {}).reduce((sum, [key, count]) => {
              const rowNum = key.replace('row', '');
              const taxRate = getTaxRateForRow(rowNum);
              return sum + (parseInt(count) || 0) * taxRate;
            }, 0),
            emp_details: Object.entries(entity.employeeCounts || {}).reduce((acc, [key, count]) => {
              if (parseInt(count) > 0) {
                const rowNum = key.replace('row', '');
                acc[`range_${rowNum}`] = {
                  range: getRangeForRow(rowNum),
                  count: parseInt(count),
                  tax: (parseInt(count) || 0) * getTaxRateForRow(rowNum)
                };
              }
              return acc;
            }, {})
          })),
          commercial_area: propertyDetails.buildingUsage === 'mixed' ? commercialArea : null
        },
        residential_business: residentialBusinessData,
        images: photoInfo,
        location: locationPayload
      };
  
      console.log('Final Payload:', payload);
  
      // Make the API call
      const response = await api.post('/survey', payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Handle success
      if (response.data && !response.data.error) {
        localStorage.setItem('formData', JSON.stringify({
          ward_id: propertyData.ward_id,
          street_id: propertyData.street_id
        }));
        
        sessionStorage.setItem('fromVerification', 'true');
        sessionStorage.setItem('lastPath', '/verify');
  
        setShowSuccessNotification(true);
        
        setTimeout(() => {
          setShowSuccessNotification(false);
          navigate(-1);
        }, 2000);
      } else {
        throw new Error(response.data?.message || 'Failed to submit survey');
      }
  
    } catch (error) {
      console.error('Submit Error:', error);
      alert(error.message || 'Failed to submit survey');
    } finally {
      setIsSubmitting(false);
    }
  };
  // Helper function to calculate total tax
  const calculateTotalTax = (entities) => {
    return entities.reduce((total, entity) => {
      const entityTax = Object.entries(entity.employeeCounts || {}).reduce((sum, [key, count]) => {
        const taxRate = getTaxRateForRow(key.replace('row', ''));
        return sum + calculateTax(count, taxRate);
      }, 0);
      return total + entityTax;
    }, 0);
  };
  // Helper functions
  function getRangeForRow(rowNum) {
    const ranges = {
      '2': '21000-31000',
      '3': '31001-45000',
      '4': '45001-60000',
      '5': '60001-75000',
      '6': '75001+'
    };
    return ranges[rowNum] || '';
  }
  
  function getTaxRateForRow(rowNum) {
    const rates = {
      '2': 135,
      '3': 315,
      '4': 690,
      '5': 1025,
      '6': 1250
    };
    return rates[rowNum] || 0;
  }
  // Make sure these helper functions are present
  const compressImage = async (dataUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Target size
        const maxWidth = 800;
        const maxHeight = 800;
        
        let width = img.width;
        let height = img.height;
        
        // Calculate dimensions
        if (width > height && width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        } else if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
        
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        
        // Compress with relatively low quality
        resolve(canvas.toDataURL('image/jpeg', 0.6));
      };
      img.src = dataUrl;
    });
  };
  
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handlePropertyDetailsChange = (field, value) => {
    setPropertyDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  if (loading || !propertyData) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent shadow-lg"></div>
      </div>
    );
  }

  const handleVerificationSubmit = async () => {
    try {
        const response = await fetch('https://ras-tndma.in/api/prod/survey', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Add any required data here, for example:
                // key1: value1,
                // key2: value2,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Success:", data);
            // Handle successful response here (e.g., show a success message)
        } else {
            console.log("Error:", response.status);
            // Handle error response here (e.g., show an error message)
        }
    } catch (error) {
        console.error("Request failed:", error);
        // Handle network or other errors here
    }
};


  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b sticky top-0 z-10 shadow-md backdrop-blur-lg bg-white/90">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-6">
              <button
                onClick={handleBack}
                className="text-gray-600 hover:text-gray-900 transition-colors p-2 hover:bg-gray-100 rounded-lg group"
              >
                <ArrowLeft className="h-6 w-6 group-hover:-translate-x-1 transition-transform" />
              </button>
              <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-3">
                <LayoutDashboard className="h-7 w-7 text-sky-500" />
                Property Verification
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">

      <DetailSection title="Property Information">
  <button
    className="lg:hidden w-full flex items-center justify-between p-2 mb-3 text-gray-700 bg-gray-100 rounded-lg"
    onClick={() => setIsMobileExpanded(!isMobileExpanded)}
  >
    <span className="font-semibold">Property Details</span>
    {isMobileExpanded ? (
      <ChevronUp className="h-4 w-4 text-gray-500" />
    ) : (
      <ChevronDown className="h-4 w-4 text-gray-500" />
    )}
  </button>
  <div className={`${!isMobileExpanded ? 'hidden lg:block' : ''}`}>
    <div className="bg-white rounded-lg border border-gray-200 p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-4">
        {/* First Row */}
        <div>
          <p className="text-sm font-bold text-gray-700">Organization name</p>
          <p className="text-xs font-medium text-gray-500 mt-1">{propertyData.area_name}</p>
        </div>
        <div>
          <p className="text-sm font-bold text-gray-700">Zone Name</p>
          <p className="text-xs font-medium text-gray-500 mt-1">{propertyData.zone_name}</p>
        </div>
        <div>
          <p className="text-sm font-bold text-gray-700">Ward Number</p>
          <p className="text-xs font-medium text-gray-500 mt-1">{propertyData.ward_name}</p>
        </div>

        {/* Second Row */}
        <div>
          <p className="text-sm font-bold text-gray-700">Street</p>
          <p className="text-xs font-medium text-gray-500 mt-1">{propertyData.street_name}</p>
        </div>
        <div>
          <p className="text-sm font-bold text-gray-700">Assessment Number</p>
          <p className="text-xs font-medium text-gray-500 mt-1">{propertyData.asst_ref}</p>
        </div>
     
      </div>
    </div>
  </div>
</DetailSection>
    {/* Owner Verification Section */}
    <DetailSection title="Owner Verification">
  <div className="space-y-4">
    <div className="flex items-center gap-4 bg-sky-50 p-4 rounded-xl">
      <div className="h-12 w-12 bg-sky-500 rounded-full flex items-center justify-center">
        <User className="h-6 w-6 text-white" />
      </div>
      <div>
        <h3 className="text-xl font-semibold text-gray-800">{propertyData.owner}</h3>
      </div>
    </div>

    <div className="space-y-4 md:mt-4">
      {/* Owner Verification Toggle */}
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="flex items-center">
          <p className="text-sm text-gray-600 ml-2">Is this owner name correct</p>
          <button
            onClick={() => {
              setOwnerVerified(!ownerVerified);
              if (!ownerVerified) setNewOwnerName('');
            }}
            className={`
              relative inline-flex h-6 w-11 items-center rounded-full ml-3
              ${ownerVerified ? 'bg-green-500' : 'bg-gray-200'}
              transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2
            `}
          >
            <span className="sr-only">Toggle owner verification</span>
            <span
              className={`
                inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out
                ${ownerVerified ? 'translate-x-6' : 'translate-x-1'}
              `}
            />
          </button>
          <span className="ml-2 text-sm text-gray-600">
            {ownerVerified ? 'Yes' : 'No'}
          </span>
        </div>
      </div>

      {/* Owner Name and Mobile Number in horizontal line with reduced width */}
      <div className="w-full md:w-1/2"> {/* Container to limit width to 50% on desktop */}
        <div className="flex flex-col md:flex-row gap-4">
          {/* Owner Name Input */}
          <div className="flex-1">
            <input
              type="text"
              value={newOwnerName}
              onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                setNewOwnerName(value);
              }}
              onKeyPress={(e) => {
                if (!/[A-Za-z\s]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={ownerVerified}
              className={`w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 text-sm ${
                ownerVerified ? 'bg-gray-100' : 'bg-white'
              }`}
              pattern="[A-Za-z\s]+"
              placeholder="Enter owner name"
            />
          </div>

          {/* Mobile Number Field */}
          <div className="flex-1">
            <input
              type="tel"
              pattern="[0-9]{10}"
              className={`w-full p-2 border ${!isMobileValid ? 'border-red-500' : 'border-gray-200'} rounded-lg focus:ring-2 ${
                isMobileValid ? 'focus:ring-blue-500 focus:border-blue-500' : 'focus:ring-red-500 focus:border-red-500'
              } transition-all duration-300`}
              value={mobileNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                setMobileNumber(value);
                if (value.length === 10) {
                  validateMobileNumber(value);
                } else {
                  setIsMobileValid(false);
                }
              }}
              onBlur={() => {
                validateMobileNumber(mobileNumber);
                setShowMobileError(true);
              }}
              required
              placeholder="Number as per property records"
            />
            {(!isMobileValid && showMobileError) && (
              <p className="text-red-500 text-sm mt-1">Please enter a valid 10-digit mobile number</p>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
</DetailSection>


{isBuilding && (
  <div className="mb-6 mt-16">
  
  <PropertyDetailsForm
      onChange={handlePropertyDetailsChange}
      data={propertyDetails}
      propertyPhotos={propertyPhotos}
      setPropertyPhotos={setPropertyPhotos}
      previousArea={propertyData.build_area}
      previousUsage={propertyData.usage_type}
      entityDetails={entityDetails}
      setEntityDetails={setEntityDetails}
      propertyData={propertyData}
      commercialArea={commercialArea}
      setCommercialArea={setCommercialArea}
      hasBusiness={hasBusiness}
      setHasBusiness={setHasBusiness}
      businessLocation={businessLocation} 
      setBusinessLocation={setBusinessLocation}
      businessWardNo={businessWardNo}
      setBusinessWardNo={setBusinessWardNo}
      businessAddress={businessAddress}
      setBusinessAddress={setBusinessAddress}
      businessName={businessName}
      setBusinessName={setBusinessName}
      businessType={businessType}
      setBusinessType={setBusinessType}
      externalLocationType={externalLocationType}
      setExternalLocationType={setExternalLocationType}
    />
  </div>
)}
{/* Submit Button */}
<div className="flex justify-center mt-12 mb-12">
<button
  onClick={handleSubmit}
  disabled={isSubmitting}
  className="px-5 py-2 rounded-xl flex items-center gap-3 bg-sky-600 text-white 
  hover:bg-sky-700 transition-all duration-300 transform hover:-translate-y-1 
  hover:shadow-lg text-lg font-semibold group disabled:opacity-50 disabled:cursor-not-allowed"
>
  {isSubmitting ? (
    <div className="animate-spin rounded-full h-6 w-6 border-2 border-white border-t-transparent" />
  ) : (
    <Check className="h-6 w-6 group-hover:scale-110 transition-transform" />
  )}
  {isSubmitting ? 'Submitting...' : 'Submit Survey'}
</button>
</div>


        {/* Success Notification Modal */}
        {showSuccessNotification && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm animate-fadeIn">
            <div className="bg-white rounded-2xl shadow-2xl w-96 transform transition-all duration-300 animate-scaleIn">
              <div className="flex items-center justify-between px-8 py-6 border-b">
                <h3 className="text-2xl font-bold text-gray-800">Success</h3>
                <button
                  className="text-gray-400 hover:text-gray-600 transition-colors"
                  onClick={() => setShowSuccessNotification(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-8 py-10">
                <div className="flex items-center justify-center mb-8">
                  <div className="relative">
                    <div className="absolute inset-0 bg-green-100 rounded-full animate-ping"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-20 w-20 text-green-500 relative z-10"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-xl text-center text-gray-700 font-medium">
                  Your data has been saved successfully!
                </p>
              </div>
              <div className="px-8 py-6 bg-gray-50 rounded-b-2xl flex justify-end">
                <button
                  className="px-6 py-3 text-white bg-blue-500 rounded-xl hover:bg-blue-600 transition-colors duration-300 font-semibold focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => setShowSuccessNotification(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerificationPage;